<template>
  <!-- 11-7 -->
  <div class="w-100 p-l-30 p-r-30">
    <div class="contents-header">
      <div class="separator">
        <p class="breadcrumb-menu">
          <router-link :to="{ name: 'Users', query: this.createSearchQuery() }" class="ashen-link">ユーザー管理</router-link>
          <i class="fas fa-angle-right form-control-color"></i> ユーザー情報
        </p>
      </div>
      <div class="card border-0 text-first mt-3">
        <div class="row d-flex justify-content-between">
          <div v-if="prevUserId" class="col-6">
            <router-link :to="linkPrevUser" class="ashen-link mx-1" replace>
              <i class="fas fa-angle-left"></i>
              {{ prevUserString }}
            </router-link>
          </div>
          <div v-else class="col-6"></div>
          <div v-if="nextUserId" class="col-6 text-end">
            <router-link :to="linkNextUser" class="ashen-link mx-1" replace>
              {{ nextUserString }}<i class="fas fa-angle-right mx-1"></i>
            </router-link>
          </div>
          <div v-else class="col-6"></div>
        </div>
      </div>
    </div>
    <div>
      <div class="card-body px-5 mt-4 m-l-100 m-r-100">
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">入場管理番号</label>
            <input v-model="user.userId" class="form-control mt-2 full-width-input" disabled
                   type="text"/>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="lastName">姓 名</label>
            <input id="lastName" v-model="user.lastName" class="form-control mt-2 half-width-input"
                   type="text"/>
          </div>
          <div v-show="errors['lastName']" class="form-error">{{ errors['lastName'] }}</div>
          <div class="col">
            <label for="firstName"></label>
            <input id="firstName" v-model="user.firstName"
                   class="form-control mt-2 half-width-input" type="text"/>
          </div>
          <div v-show="errors['firstName']" class="form-error">{{ errors['firstName'] }}</div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">メールアドレス</label>
            <input v-model="user.email" class="form-control mt-2 full-width-input" type="email"/>
          </div>
          <div v-show="errors['email']" class="form-error">{{ errors['email'] }}</div>
        </div>
        <div class="row mt-4 dob-section">
          <label class="p-l-0">生年月日</label>
          <div class="d-inline-flex mt-1 p-l-0 p-r-0">
            <div class="input-group custom-select-year parent-block">
              <div class="child-block">年</div>
              <select
                  id="inputGroupSelectYear"
                  v-model="year"
                  class="form-control select_year"
              >
                <option v-for="year in years" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>
            <div class="input-group custom-select-year parent-block">
              <div class="child-block">月</div>
              <select
                  id="inputGroupSelectMonth"
                  v-model="month"
                  class="form-control select_month"
              >
                <option selected value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </div>
            <div class="input-group custom-select-year parent-block m-r-0">
              <div class="child-block">日</div>
              <select
                  id="inputGroupSelectDate"
                  v-model="bdate"
                  class="form-control select_date"
              >
                <option selected value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
              </select>
            </div>
          </div>
          <div v-show="errors['birthday']" class="form-error">{{ errors['birthday'] }}</div>
        </div>
        <!--                        <div class="row mt-4">-->
        <!--                            <div class="col">-->
        <!--                                <label for="exampleInputPassword1">生年月日</label>-->
        <!--                                <div class="input-group">-->
        <!--                                    <input class="form-control mt-2 full-width-input" type="text"-->
        <!--                                           v-model="user.birthday"/>-->
        <!--                                    <div class="input-group-append mt-2">-->
        <!--                                        <input @change="getBirthdayDateFormat" class="form-control datepicker"-->
        <!--                                               type="date"-->
        <!--                                               v-model="date">-->
        <!--                                    </div>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <div class="row mt-4">
          <div class="col">
            <label>JTA管理者</label>
            <select v-model="user.isJTA" class="form-control filter-select-long form-select mt-1">
              <option :value="true">JTA管理者</option>
              <option :value="false">なし</option>
            </select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="form-group">
            <label>性別</label>
            <div class="mt-2">
              <div class="form-check form-check-inline"><input
                  id="radio1_0"
                  v-model="user.gender" :value=Number(2) class="form-check-input  mt-2"
                  type="radio"><label class="form-check-label mt-1" for="radio1_0">女性</label>
              </div>
              <div class="form-check form-check-inline"><input
                  id="radio1_1"
                  v-model="user.gender" :value=Number(1) class="form-check-input mt-2"
                  type="radio"><label class="form-check-label mt-1" for="radio1_1">男性</label>
              </div>
              <div class="form-check form-check-inline"><input
                  id="radio1_2"
                  v-model="user.gender" :value=Number(3) class="form-check-input  mt-2"
                  type="radio"><label class="form-check-label mt-1"
                                      for="radio1_2">回答しない</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <div class="form-group">
              <label for="inputCountryOfCitizenship">国籍
              </label>
              <select id="inputCountryOfCitizenship" v-model="user.nationalityId"
                      class="form-control mt-1 form-select citizenship">
                <option v-for="obj in nationalityList"
                        v-bind:value="obj.nationalityId">{{ obj.nationalityName }}
                </option>
              </select>
            </div>
            <div v-show="errors['nationalityId']" id="err_country" class="form-error">
              {{ errors['nationalityId'] }}
            </div>
            <!--                                <label for="exampleInputPassword1">国籍</label>-->
            <!--                                <input class="form-control mt-2 full-width-input" type="text"-->
            <!--                                       v-model="user.nationalityName"/>-->
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">住所</label>
            <input v-model="user.address" class="form-control mt-2 full-width-input" type="text"/>
          </div>
          <div v-show="errors['address']" class="form-error">{{ errors['address'] }}</div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">電話番号</label>
            <input v-model="user.phoneNo" class="form-control mt-2 half-width-input" type="text"/>
            <div v-show="errors['phoneNo']" class="form-error">{{ errors['phoneNo'] }}</div>
          </div>
        </div>
        <!--
        <div class="row mt-3">
          <div class="form-group">
            <label>新型コロナワクチン接種</label>
            <select v-model="user.coronaVaccinationCount" class="form-control filter-select-long form-select mt-1">
              <option :value="0">なし</option>
              <option :value="1">1回目</option>
              <option :value="2">2回目</option>
              <option :value="3">3回目</option>
              <option :value="4">4回目</option>
              <option :value="5">5回目</option>
              <option :value="6">6回目</option>
              <option :value="7">7回目</option>
              <option :value="8">8回目</option>
              <option :value="9">9回目</option>
              <option :value="10">10回目</option>
            </select>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">最後に接種した日</label>
            <div class="input-group">
              <Calender :currentDate="user.latestCoronaVaccinationDate"
                        :disable="user.coronaVaccinationCount ==null||user.coronaVaccinationCount==0"
                        :type="'vaccination'"
                        @update="updateCoronaDate"
              ></Calender>
            </div>
          </div>
          <div v-show="errors['latestCoronaVaccinationDate']" class="form-error">
            {{ errors['latestCoronaVaccinationDate'] }}
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">PCR検査</label>
            <div class="mt-2">
              <div class="form-check form-check-inline">
                <input id="pcrPositive"
                       v-model="user.latestPcrTestResult"
                       :value=Number(1) class="form-check-input  mt-2"
                       type="radio"><label class="form-check-label mt-1"
                                           for="pcrPositive">陽性</label>
              </div>
              <div class="form-check form-check-inline"><input
                  id="pcrNegative"
                  v-model="user.latestPcrTestResult"
                  :value=Number(-1) class="form-check-input mt-2"
                  type="radio"><label class="form-check-label mt-1"
                                      for="pcrNegative">陰性</label>
              </div>
              <div class="form-check form-check-inline"><input
                  id="pcrNotTested"
                  v-model="user.latestPcrTestResult"
                  :value=Number(0) class="form-check-input mt-2"
                  type="radio"><label class="form-check-label mt-1"
                                      for="pcrNotTested">未検査</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">最後に検査した日</label>
            <div class="input-group">
              <Calender :currentDate="user.latestPcrTestDate"
                        :disable="user.latestPcrTestResult == 0 || user.latestPcrTestResult == null"
                        :type="'pcr'"
                        @update="updatePcrDate"
              ></Calender>

            </div>
          </div>
          <div v-show="errors['latestPcrTestDate']" class="form-error">{{ errors['latestPcrTestDate'] }}</div>
        </div>
          -->

        <div class="row medical-history mt-2">
          <div class="col">
            <div class="form-group">
              <label>既往歴について</label>
              <div class="m-t-10">
                <div class="form-check mx-auto m-t-10">
                  <input
                      id="anemia"
                      v-model="user.anemia"
                      class="form-check-input"
                      type="checkbox"
                  />
                  <label class="form-check-label" for="anemia">貧血</label>
                </div>
                <div class="form-check mx-auto m-t-10">
                  <input
                      id="asthma"
                      v-model="user.asthma"
                      class="form-check-input"
                      type="checkbox"
                  />
                  <label class="form-check-label" for="asthma">ぜんそく</label>
                </div>
                <div class="form-check mx-auto m-t-10">
                  <input
                      id="heartDisease"
                      v-model="user.heartDisease"
                      class="form-check-input"
                      type="checkbox"
                  />
                  <label class="form-check-label" for="heartDisease">心臓病</label>
                </div>
                <div class="form-check mx-auto m-t-10">
                  <input
                      id="kidneyDisease"
                      v-model="user.kidneyDisease"
                      class="form-check-input"
                      type="checkbox"
                  />
                  <label class="form-check-label" for="kidneyDisease">腎臓病</label>
                </div>
                <div class="form-check mx-auto m-t-10">
                  <input
                      id="epilepsy"
                      v-model="user.epilepsy"
                      class="form-check-input"
                      type="checkbox"
                  />
                  <label class="form-check-label" for="epilepsy">てんかん</label>
                </div>
                <div class="form-check mx-auto m-t-10">
                  <input
                      id="highBloodPressure"
                      v-model="user.highBloodPressure"
                      class="form-check-input"
                      type="checkbox"
                  />
                  <label class="form-check-label" for="highBloodPressure">高血圧</label>
                </div>
                <div class="form-check mx-auto m-t-10">
                  <input
                      id="diabetes"
                      v-model="user.diabetes"
                      class="form-check-input"
                      type="checkbox"
                  />
                  <label class="form-check-label" for="diabetes">糖尿病</label>
                </div>
                <div class="form-check mx-auto m-t-10">
                  <input
                      id="medicalHistoryOther"
                      v-model="user.other"
                      class="form-check-input"
                      type="checkbox"
                      @click="user.other!==user.other"
                  />
                  <label class="form-check-label" for="medicalHistoryOther">その他</label>
                </div>

                <div v-if="user.other" class="form-check p-l-0 mx-auto">
                  <textarea id="otherText" v-model="user.otherText" class="form-control" rows="2"></textarea>
                  <div v-show="errors['otherText']" id="err_other" class="form-error">{{ errors['otherText'] }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-6">
            <button class="btn btn-outline-primary reset-button height-44 w-100 font-weight-bold mx-auto mt-4"
                    @click="showModal.resetPassword = !showModal.resetPassword">
              パスワードリセット
            </button>
            <div id="resetPasswordModal">
              <transition @enter="startTransitionModal" @after-enter="endTransitionModal"
                          @after-leave="startTransitionModal" @before-leave="endTransitionModal">
                <div v-if="showModal.resetPassword" ref="modal" class="modal fade">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title">パスワードリセット</h5>
                        <button class="close"
                                type="button"
                                @click="showModal.resetPassword = !showModal.resetPassword">
                          <span aria-hidden="true">×</span></button>
                      </div>
                      <div class="modal-body">{{ user.lastName }} {{ user.firstName }}
                        のパスワードをリセットします。<br> よろしいですか？
                      </div>
                      <div class="modal-footer">
                        <button class="btn btn-secondary"
                                @click="showModal.resetPassword = !showModal.resetPassword">
                          キャンセル
                        </button>
                        <button class="btn btn-primary" type="button" @click="resetPassword">
                          リセット
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
              <div ref="backdrop" class="modal-backdrop fade d-none"></div>
            </div>
          </div>
          <div class="col-6">
            <button v-if="accountStatus === 1"
                    class="btn btn-primary height-44 w-100 font-weight-bold mx-auto mt-4"
                    @click="showModal.accountSuspension = !showModal.accountSuspension">
              アカウント停止
            </button>
            <div id="accountSuspensionModal">
              <transition @enter="startTransitionModal" @after-enter="endTransitionModal"
                          @after-leave="startTransitionModal" @before-leave="endTransitionModal">
                <div v-if="showModal.accountSuspension" ref="modal" class="modal fade">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 id="exampleModalLabel" class="modal-title">アカウント停止</h5>
                        <button class="close"
                                type="button"
                                @click="showModal.accountSuspension = !showModal.accountSuspension">
                          <span aria-hidden="true">×</span></button>
                      </div>
                      <div class="modal-body">{{ user.lastName }} {{ user.firstName }}
                        のアカウントを停止します。<br> よろしいですか？
                      </div>
                      <div class="modal-footer">
                        <button class="btn btn-secondary"
                                @click="showModal.accountSuspension = !showModal.accountSuspension">
                          キャンセル
                        </button>
                        <button class="btn btn-primary" type="button" @click="suspendUser">
                          停止
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
              <div ref="backdrop" class="modal-backdrop fade d-none"></div>
            </div>
            <button v-if="accountStatus === 2"
                    class="btn btn-outline-primary reset-button height-44 w-100 font-weight-bold mx-auto mt-4"
                    @click="showModal.accountResume = !showModal.accountResume">
              アカウント停止解除
            </button>
            <div id="accountResumeModal">
              <transition @enter="startTransitionModal" @after-enter="endTransitionModal"
                          @after-leave="startTransitionModal" @before-leave="endTransitionModal">
                <div v-if="showModal.accountResume" ref="modal" class="modal fade">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title">アカウント停止解除</h5>
                        <button class="close"
                                type="button"
                                @click="showModal.accountResume = !showModal.accountResume">
                          <span aria-hidden="true">×</span></button>
                      </div>
                      <div class="modal-body">{{ user.lastName }} {{ user.firstName }}
                        のアカウント停止を解除します。<br> よろしいですか？
                      </div>
                      <div class="modal-footer">
                        <button class="btn btn-secondary"
                                @click="showModal.accountResume = !showModal.accountResume">
                          キャンセル
                        </button>
                        <button class="btn btn-primary" type="button" @click="resumeUser">
                          解除
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
              <div ref="backdrop" class="modal-backdrop fade d-none"></div>
            </div>
          </div>

        </div>

        <div class="row mt-5">
          <div class="col-4">
            <div class="btn-width">
              <Modal :back-to-root="backToUser" :data="user" :reload-data="getUser"
                     :user-delete="deleteUser"
                     action="deleteUser"
                     body=" に紐付いているすべての情報が削除され、この操作は後戻りできません。" button-title="削除" cancel-title="キャンセル"
                     confirmation-msg="ユーザーの削除を実行します。"
                     confirmation-msg2="よろしいですか？" ok-title="削除" title="削除"></Modal>
            </div>
          </div>
          <div class="col-8 text-end">
            <button class="btn btn-light bg-white ashen-link text-decoration-none light border-0 btn-width"
                    @click="backToUser">
              キャンセル
            </button>
            <button class="btn col btn-primary btn-outline-light font-weight-bold btn-width float-end"
                    @click="updateUser">変更
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {apiConfig, app, gender} from '@/constants'
import Datatable from '../components/datatable/Datatable'
import {mapGetters} from 'vuex'
import Modal from "../components/modal/Modal";
import Common from "jsadminclient/common";
import {AdminNationalityItem, AdminNationalityItems, SetAdminJTAUserChangeStatus} from "jsadminclient";
import Calender from "../components/layouts/Calendar";

export default {
  name: "UserDetail",
  components: {Datatable, Modal, Calender},
  props: {
    userId: {type: String, default: null},
    accountStatus: {type: Number, default: null}
  },
  watch: {
    $route(to, from) {
      // Called from back or next
      this.getUser()
    },
    'user.latestPcrTestResult': function (newValue, oldValue) {
      if (newValue === 0) {
        this.user.latestPcrTestDate = ''
      } else if (newValue === -1 || newValue === 1) {
        this.user.latestPcrTestDate = this.pcrTestDate
      }
    },
    'user.coronaVaccinationCount': function (newValue, oldValue) {
      this.user.latestCoronaVaccinationDate = newValue ? this.coronaVaccinationDate : ''
       if (newValue > 0) {
        this.user.hasCoronaVaccination = true
      } else {
        this.user.hasCoronaVaccination = false
      }
    }

  },
  data() {
    return {
      app: app,
      apiConfig: apiConfig,
      showModal: {
        resetPassword: false,
        accountSuspension: false,
        accountResume: false
      },
      nationalityList: [],
      nationalityId: '',
      uploadResponseMessage: [],
      deleteSuccess: false,
      deleteFailed: false,
      gender: gender,
      hasCoronaVaccination: '',
      loading: true,
      addUserScreen: false,
      //accountSuspend: true,
      addNewBulkUserScreen: false,
      batchRegistrationUserConfirmationScreen: false,
      batchRegistrationUserSuccessMsgScreen: false,
      tournamentUserListScreen: false,
      reloadKey: 1,
      nextUserId: '',
      nextUserName: '',
      prevUserId: '',
      prevUserName: '',
      user: '',
      date: '',
      vaccinationDate: '',
      year: "",
      month: "",
      bdate: "",
      pcrTestDate: '',
      coronaVaccinationDate: '',
      pcrTestResultDate: '',
      errors: []
    }
  },
  mounted() {
    this.apiConfig.headers['X-API-Token'] = this.config.token
    this.initialPageLoadHandler()
  },
  computed: {
    ...mapGetters(['config']),
    years() {
      const year = new Date().getFullYear();
      return Array.from(
          {length: year - 1919},
          (value, index) => 1919 + (index + 1)
      )
    },
    prevUserString() {
      return this.prevUserName + '(' + this.prevUserId + ')'
    },
    nextUserString() {
      return this.nextUserName + '(' + this.nextUserId + ')'
    },
    linkPrevUser() {
      return {
        name: "UserDetail",
        params: {
          userId: this.prevUserId,
        },
        query: this.createSearchQuery()
      }
    },
    linkNextUser() {
      return {
        name: "UserDetail",
        params: {
          userId: this.nextUserId,
        },
        query: this.createSearchQuery()
      }
    },
    isAccountSuspend() {
      return this.accountStatus === 2
    }
  },
  methods: {
    initialPageLoadHandler() {
      $(".tooltip").hide()
      this.getUser()
      this.getNationality()
    },
    startTransitionModal() {
      this.$refs.backdrop.classList.toggle("d-block");
      this.$refs.modal.classList.toggle("d-block");
      $(".modal").css({
        'zIndex': '999999'
      })
      $(".modal-dialog").css({
        'margin': '15.75rem auto'
      })
      $("#wrapper").css({
        'zIndex': '0'
      })
    },
    endTransitionModal() {
      this.$refs.backdrop.classList.toggle("show");
      this.$refs.modal.classList.toggle("show");
    },
    backToUser() {
      this.$router.push({name: 'Users', params: {defaultResponse: this.defaultResponse}})
    },
    getApiConfig() {
      return new AdminApi.ApiConfig(
          this.apiConfig.basePath,
          this.apiConfig.headers
      )
    },
    getVaccinationDateFormat(e) {
      this.vaccinationDate = moment(e.target.value).format('YYYY/MM/DD');
      this.user.latestCoronaVaccinationDate = this.vaccinationDate
    },
    getPcrTestResultDateFormat(e) {
      this.pcrTestResultDate = moment(e.target.value).format('YYYY/MM/DD');
      this.user.latestPcrTestDate = this.pcrTestResultDate
    },
    // nextUser() {
    //     const defaultResponse = this.defaultResponse
    //     this.$router.replace({name: 'UserDetail', params: {userId: this.nextUserId}}).finally(() => {
    //         this.userId = this.nextUserId
    //         this.defaultResponse = defaultResponse
    //         this.getUser()
    //     })
    // },
    // prevUser() {
    //     const defaultResponse = this.defaultResponse
    //     this.$router.replace({name: 'UserDetail', params: {userId: this.prevUserId}}).finally(() => {
    //         this.userId = this.prevUserId
    //         this.defaultResponse = defaultResponse
    //         this.getUser()
    //     })
    // },
    async getUser() {
      try {
        const api = new AdminApi.JTAUserApi(this.getApiConfig())
        const result = await api.jtaUsersUserIdGetAsync(
            this.userId,
            this.$route.query.adminType,
            this.$route.query.accountStatus,
            this.$route.query.keyword,
            this.$route.query.sort,
            this.$route.query.order,
            this.$route.query.fromMail ? 1 : 0
        )
        this.user = result.user
        let dob = this.user.birthday.split('/')
        this.year = dob[0]
        this.month = dob[1]
        this.bdate = dob[2]
        this.accountStatus = result.accountStatus
        this.pcrTestDate = this.user.latestPcrTestDate
        this.coronaVaccinationDate = this.user.latestCoronaVaccinationDate
        this.nextUserId = result.nextUserId
        this.nextUserName = result.nextUserName
        this.prevUserId = result.prevUserId
        this.prevUserName = result.prevUserName
      } catch (e) {
        this.user = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async updateUser() {
      try {
        const api = new AdminApi.JTAUserApi(this.getApiConfig())
        this.user.birthday = this.year + "/" + this.month + "/" + this.bdate
        var result = await api.jtaUsersUserIdPostAsync(
            this.userId,
            this.user
        );
        Common.showToast("ユーザー情報が変更されました。")
        this.backToUser()
      } catch (e) {
        this.loading = false
        this.errors = Common.getErrorList(e.response, this.errors)
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async deleteUser() {
      try {
        var api = new AdminApi.JTAUserApi(this.getApiConfig())
        var result = await api.jtaUsersUserIdDeleteAsync(
            this.userId
        )
        Common.showToast("ユーザーが削除されました。")
        if (result) {
          this.loading = false
          this.getUser()
          // this.backToUser()
        }
      } catch (e) {
        this.loading = false
        this.backToUser()
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async resetPassword() {
      try {
        var api = new AdminApi.JTAUserApi(this.getApiConfig())
        var result = await api.jtaUsersUserIdResetPasswordPostAsync(
            this.userId
        )
        Common.showToast("パスワードをリセットしました。")
        if (result) {
          this.loading = false
          this.backToUser()
        }
        this.getUser()
      } catch (e) {
        this.loading = false
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async suspendUser() {
      try {
        var item = SetAdminJTAUserChangeStatus.createFromJson({
          accountStatus: 2 /* Disabled */
        });
        var api = new AdminApi.JTAUserApi(this.getApiConfig())
        var result = await api.jtaUsersUserIdChangeStatusPostAsync(
            this.userId, item
        )
        Common.showToast("アカウントが停止されました。")
        if (result) {
          this.loading = false
          this.backToUser()
        }
        this.getUser()
      } catch (e) {
        this.loading = false
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async resumeUser() {
      try {
        var item = SetAdminJTAUserChangeStatus.createFromJson({
          accountStatus: 1 /* Enabled */
        });
        var api = new AdminApi.JTAUserApi(this.getApiConfig())
        var result = await api.jtaUsersUserIdChangeStatusPostAsync(
            this.userId, item
        )
        Common.showToast("アカウントの停止が解除されました。")
        if (result) {
          this.loading = false
          this.backToUser()
        }
        this.getUser()
      } catch (e) {
        this.loading = false
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getNationality() {
      const token = this.config.token
      try {
        const api = Common.createSystemApi(token)
        const jsonObject = await api.systemNationalitiesGetAsync()
        const result = AdminNationalityItems.createFromJson(jsonObject)

        this.nationalityList = result.items
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    updateCoronaDate(date) {
      this.user.latestCoronaVaccinationDate = date
    },
    updatePcrDate(date) {
      this.user.latestPcrTestDate = date
    },
    createSearchQuery() {
      return Common.appendSearchQuery(this.$route.query)
    },
  }
}
</script>
<style lang="scss" scoped>

.datepicker {
  &::-webkit-calendar-picker-indicator {
    background: url("../assets/images/calendar-event.svg") no-repeat;
  }
}

.button-container {
  padding: 0px 186px;
}

:disabled {
  background: #FAF7F9 !important;
}

.form-control {
  //background: #F2ECF0;
}

.yes {
  width: 225px;
  height: 44px;
  color: white;
  background-color: #C95D6C;
}

.no {
  width: 225px;
  height: 44px;
  color: #333333;
  border: none;
  background-color: #FFFFFF;
}

/*.input-group{*/
/*    width: 720px;*/
/*}*/

::placeholder {
  color: #333333;
}

.custom-button {
  background: #C95D6C;
  color: #FFFFFF;
  border-radius: 4px;
  width: 160px;
}

.reset-button {
  &:hover {
    color: #fff;
    background-color: #C95D6C;
    border-color: #C95D6C;
  }

  &:focus {
    color: #C95D6C;
    border-color: #C95D6C;
  }

  &:active {
    color: #fff;
    background-color: #C95D6C;
    border-color: #C95D6C;
  }
}

.custom-button-outline.btn {
  background: #FFFFFF;
  color: #C95D6C;
  border: 1px solid #C95D6C;
  border-radius: 4px;
  width: 160px;
}

.card-container {
  padding: 7rem !important;
  padding-top: 2rem !important;
}

a {
  color: #333333;
}

.btn-holo {
  border: 1px solid #C95D6C;
  opacity: 1;
}

.dob-section {
  .parent-block {
    .child-block {
      position: absolute;
      top: 0px;
      background: #F8F8F8;
      justify-content: center;
      align-items: center;
      display: flex;
      border: 1px solid #F0E7ED;
      border-radius: 0px 4px 4px 0px;
      height: 100%;
      max-height: 48px;
      width: 2.25rem;
      z-index: 9999;
      right: 0;
      color: #888888;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }

  .form-control {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
}

.filter-select {
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  height: 32px !important;
}

.filter-select-long {
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  height: 44px !important;

}

.custom-select-year {
  width: 28%;
  margin-right: 23px;

  &:first-child {
    width: 40%;
  }

  &:last-child {
    width: 28%;
    margin-right: 0px !important;
  }
}

.form-check {
  &-input:disabled, &-input[disabled], &-input:disabled ~ &-label, &-input[disabled] ~ &-label {
    opacity: 1;
  }
}
</style>
